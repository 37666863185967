<!-- 开票资料弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'修改开票资料':'新增开票资料'"
      @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="90px">
      <el-form-item label="公司名称" prop="company_name">
        <el-input
          placeholder="请输入"
          v-model="form.company_name"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="开票税号" prop="tax_number">
        <el-input
          placeholder="请输入"
          v-model="form.tax_number"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="deposit_bank">
        <el-input
          placeholder="请输入"
          v-model="form.deposit_bank"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="银行账户" prop="bank_number">
        <el-input
          placeholder="请输入"
          v-model="form.bank_number"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="电话号码" prop="phone">
        <el-input
          placeholder="请输入"
          v-model="form.phone"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="企业地址" prop="enterprise_address">
        <el-input
          placeholder="请输入"
          v-model="form.enterprise_address"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="邮箱地址" prop="delivery_address">
        <el-input
          placeholder="请输入"
          v-model="form.delivery_address"
          clearable>
        </el-input>
      </el-form-item>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import {save_invoice,Editinvoice} from "@/api/custom";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 父级id
    id: Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        company_name:[
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在2 到 50个字符', trigger: 'blur' }
        ],
        tax_number: [
          { required: true, message: '请输入开票税号', trigger: 'blur' },
          { min: 18, max: 18, message: '长度至少需要18个字符', trigger: 'blur' }
        ],
        deposit_bank: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在2 到 50个字符', trigger: 'blur' }
        ],
        bank_number: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          { min: 10, max: 20, message: '长度在10 到 20个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          // {
          //   pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          //   message: "请输入正确的电话号码",
          //   trigger: "blur"
          // }
          { min: 11, max: 11, message: '长度需要11个字符', trigger: 'blur' }
        ],
        enterprise_address: [
          { required: true, message: '请输入企业地址', trigger: 'blur' },
          { min: 2, max: 80, message: '长度在2 到 80个字符', trigger: 'blur' }
        ],
        delivery_address: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在2 到 50个字符', trigger: 'blur' }
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    // 新增修改都需要添加这个id
    this.form.facilitator_id = this.id;
    console.log(this.form);
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.isUpdate === false){
            this.form.channel_id = this.id;
            save_invoice(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            this.form.channel_id = this.id;
            Editinvoice(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }

        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
